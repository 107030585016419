import { ComponentsLocaleTypes } from '../locales.types'

const componentsLocaleBo: ComponentsLocaleTypes = {
  layout: {
    skipLink: {
      overall: 'Hopp til {{ content }}',
      header: 'overskrift',
      aside: 'menysider',
      main: 'innhold'
    }
  },
  search: {
    placeholder: {
      default: 'Søk...',
      assignments: 'Søk etter oppgavenavn​...',
      students: 'Søk etter studenter...',
      class: 'Søk etter klasse...',
      games: 'Søk etter spill...'
    },
    noOptions: {
      default: 'Ingen resultater'
    },
    suggestions: {
      title: 'Forslag'
    },
    loading: {
      default: 'Laster...'
    }
  },
  button: {
    template: {
      addNewGroup: {
        text: 'Lag ny gruppe'
      }
    },
    text: {
      viewResults: 'Vis resultater',
      report: 'Se rapport',
      edit: 'Rediger',
      delete: 'Slett',
      newAssignment: 'Ny oppgave',
      createNewGroup: 'Lag en ny gruppe',
      selectLanguage: 'Velg språk',
      createNewAssignment: 'Lag en ny oppgave',
      reports: 'Rapporter',
      moreInfo: 'Mer info',
      offlineContent: 'Oppdragsark',
      buyLicenses: 'Kjøp lisenser',
      assignLicenses: 'Tildel lisenser',
      manage: 'Administrer',
      readMore: 'Les mer',
      addCustomer: 'Legg til kunde',
      addLicenses: 'Legg til lisenser',
      addInvoice: 'Legg til lisens',
      successfullyAdded: 'Vallykket lagt til'
    }
  },
  groupListItem: {
    students: '{{ studentCount }} elever'
  },
  gameCard: {
    more: 'Mer...',
    topics: 'Temaer',
    skills: 'Ferdigheter:',
    tags: 'Tags:',
    boards: '{{ boardsCount }} oppgaver',
    chooseGameModes: 'Velg spillmodus:',
    chooseModes: 'Velg modus',
    planet: 'Planet: {{ planetName }}',
    needsSchoolLicense: 'Krever Numetry Skole lisens'
  },
  gamesMode: {
    modeOne: 'Modus I',
    modeTwo: 'Modus II',
    modeThree: 'Modus II',
    modeFour: 'Modus IV',
    challengeModeOne: 'Utfordring I',
    challengeModeTwo: 'Utfordring II'
  },
  modals: {
    confirmation: {
      assignment: {
        create: {
          title: 'Oppgave opprettet',
          subtitle: '<strong>{{ name }}</strong> er lagt til oppgavelisten din'
        },
        update: {
          title: 'Endringer lagret',
          subtitle:
            '<strong>{{ name }}</strong> har blitt oppdatert med dine endringer.'
        }
      }
    },
    error: {
      assignment: {
        create: {
          title: 'En uventet feil har oppstått.',
          subtitle:
            'Oi, noe gikk galt. Prøv på nytt, eller kontakt oss så skal vi hjelpe deg.'
        },
        update: {
          title: 'En uventet feil har oppstått.',
          subtitle:
            'Oi, noe gikk galt. Prøv på nytt, eller kontakt oss så skal vi hjelpe deg.'
        }
      }
    },
    gameDetails: {
      topics: 'Temaer:',
      skills: 'Ferdigheter:',
      gameModes: 'Moduser:',
      tags: 'Tags:',
      levelInfo: 'Oversikt',
      preClassroom: 'Faglig bakgrunn',
      afterPlaying: 'Relaterte ressurser',
      whatTheKidsShouldDoNow: 'Ressurser',
      relatedGames: 'Se også:',
      tasks: '{{ nrOfTasks }} oppgaver',
      curriculumObjectives: 'Læreplanmål',
      funFact: 'Fun Fact',
      missionDetails: 'Oppdragsdetaljer',
      selectModes: 'Velg modus',
      planet: 'Planet: {{ planetName }}',
      modesSubtitle:
        'Nedenfor finner du en oversikt over det faglige innholdet i de ulike modusene. For å tildele oppdrag til elevene dine, trykk på “Lag en oppgave” i hovedmenyen.',
      readTheGaneHelpArticle: 'Les hjelpeartikkelen for {{ gameName }}'
    },
    assignTo: {
      title: 'Legg til elever',
      subtitle:
        'Velg hvilke elever eller grupper som skal motta denne oppgaven.',
      wholeClass: 'Hele klassen',
      students: 'Elever',
      groups: 'Grupper'
    },
    editGroup: {
      title: 'Rediger gruppe',
      groupLabel: 'Gruppenavn',
      studentsInThisGroup: 'Elever i denne gruppen',
      addedToThisGroup: 'lagt til i denne gruppen',
      studentCount_one: '<strong>{{ count }} elev</strong>',
      studentCount_other: '<strong>{{ count }} elever</strong>',
      showingNStudents: 'Viser {{ count }} elever',
      showingNStudents_zero: 'Ingen elev funnet',
      findStudentsToAdd: 'Finn elever å legge til',
      enterGroupName: 'Lag et gruppenavn',
      groupSelectPlaceholder: 'Velg gruppe',
      errorMessage: {
        pleasePutGroupName: 'Gruppenavn mangler'
      }
    },
    createGroup: {
      title: 'Lag gruppe',
      groupLabel: 'Gruppenavn',
      table: {
        name: 'Elever lagt til i gruppen​​',
        userGroups: 'Feide klasse'
      },
      input: {
        placeholder: 'Skriv inn et gruppenavn'
      },
      filters: {
        select: 'Velg etter gruppe',
        search: 'Søk etter elev​'
      },
      button: {
        confirm: 'Lag ny gruppe'
      }
    }
  },
  slider: {
    from: 'Fra',
    to: 'Til'
  },
  userTableRow: {
    results: '{{ name }} sine resultater'
  },
  table: {
    pagination: {
      previous: 'Forrige',
      next: 'Neste'
    },
    notFoundImageAlt: 'Jente forvirret',
    notFoundTip: 'Prøv å søke etter en annen {{ entity }}tittel',
    noResultsFound: 'Ingen resultater',
    noResultsFoundDescription:
      'Ingen resultater ble funnet for denne tabellen, prøv å opprette et nytt element eller oppdater siden',
    noRowsWithoutSearch: 'Ingen {{ entity }} funnet',
    noRowsWithSearch: 'Vi fant ingen {{ entity }}  med navnet «{{ search }}»'
  },
  picker: {
    from: 'Fra',
    to: 'Til'
  },
  pdfs: {
    symbols: {
      equals: {
        symbol: '=',
        name: 'er lik'
      },
      multiplication: {
        symbol: '·',
        name: 'multiplikasjon'
      },
      division: {
        symbol: ':',
        name: 'inndeling'
      },
      fraction: {
        symbol: '/',
        name: 'brøkdel'
      },
      addition: {
        symbol: '+',
        name: 'addisjon'
      },
      subtraction: {
        symbol: '-',
        name: 'subtraksjon'
      }
    },
    worksheet: {
      name: 'Navn',
      class: 'Klasse',
      answerSheet: {
        title: 'Fasit'
      }
    }
  }
}

export default componentsLocaleBo
